import revive_payload_client_3pP6hKItL7 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.0.1_@types+node@22.7.4_eslint@9.11.1_rollup@4.23.0_typescript@5._uvjjj6a7gqiofdzkd5ua6sjxia/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_o0pJYKwZ6e from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.0.1_@types+node@22.7.4_eslint@9.11.1_rollup@4.23.0_typescript@5._uvjjj6a7gqiofdzkd5ua6sjxia/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8G7JdkrSXl from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.0.1_@types+node@22.7.4_eslint@9.11.1_rollup@4.23.0_typescript@5._uvjjj6a7gqiofdzkd5ua6sjxia/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_aaZutcdRqt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.0.1_@types+node@22.7.4_eslint@9.11.1_rollup@4.23.0_typescript@5._uvjjj6a7gqiofdzkd5ua6sjxia/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_otEZTGJ2cR from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.0.1_@types+node@22.7.4_eslint@9.11.1_rollup@4.23.0_typescript@5._uvjjj6a7gqiofdzkd5ua6sjxia/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_HvEfJJGq81 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.0.1_@types+node@22.7.4_eslint@9.11.1_rollup@4.23.0_typescript@5._uvjjj6a7gqiofdzkd5ua6sjxia/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Fg7XlAn9e4 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.0.1_@types+node@22.7.4_eslint@9.11.1_rollup@4.23.0_typescript@5._uvjjj6a7gqiofdzkd5ua6sjxia/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_8e3crQweFQ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@netlify+blobs@8.0.1_@types+node@22.7.4_eslint@9.11.1_rollup@4.23.0_typescript@5._uvjjj6a7gqiofdzkd5ua6sjxia/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_hqt2zzMufO from "/opt/build/repo/node_modules/.pnpm/dayjs-nuxt@2.1.11_rollup@4.23.0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import sentry_client_6zqCp9ChtE from "/opt/build/repo/node_modules/.pnpm/@sentry+nuxt@8.32.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_lnkn7e4xtm2q7krudhinzhussy/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import plugin_EjG6yUN65n from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.5.2_rollup@4.23.0_vite@5.4.8_vue@3.5.10/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Tp9C78Kwzg from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_rollup@4.23.0_vite@5.4.8_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import clerk_XbpSWsrCmw from "/opt/build/repo/plugins/clerk.ts";
export default [
  revive_payload_client_3pP6hKItL7,
  unhead_o0pJYKwZ6e,
  router_8G7JdkrSXl,
  payload_client_aaZutcdRqt,
  navigation_repaint_client_otEZTGJ2cR,
  check_outdated_build_client_HvEfJJGq81,
  chunk_reload_client_Fg7XlAn9e4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8e3crQweFQ,
  plugin_hqt2zzMufO,
  sentry_client_6zqCp9ChtE,
  sentry_client_config_o34nk2sJbg,
  plugin_EjG6yUN65n,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Tp9C78Kwzg,
  clerk_XbpSWsrCmw
]